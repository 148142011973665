<template>
    <div class="message-management">
      <h3>Mesajlar Yönetimi</h3>
  
      <h4>{{ isEdit ? "Mesajı Düzenle" : "Yeni Mesaj Ekle" }}</h4>
      <form @submit.prevent="isEdit ? updateMessage() : createMessage()">
        <input v-model="selectedMessage.web_id" placeholder="Web ID" required />
        <input v-model="selectedMessage.message_type_id" placeholder="Mesaj Tipi ID" required />
        <input v-model="selectedMessage.from_user_id" placeholder="Gönderen Kullanıcı ID" required />
        <input v-model="selectedMessage.responder_user_id" placeholder="Yanıtlayan Kullanıcı ID" />
        <input v-model="selectedMessage.subject" placeholder="Konu" required />
        <textarea v-model="selectedMessage.message" placeholder="Mesaj" required></textarea>
        <input v-model="selectedMessage.status" placeholder="Durum" required />
        <input v-model="selectedMessage.vote" type="number" placeholder="Oylama Sayısı" required />
        <button type="submit">{{ isEdit ? "Güncelle" : "Ekle" }}</button>
        <button v-if="isEdit" @click="cancelEdit" type="button">İptal</button>
      </form>
  
      <h4>Tüm Mesajlar</h4>
      <ul>
        <li v-for="msg in messages" :key="msg.message_id">
          <span>
            <strong>Konu:</strong> {{ msg.subject }} - 
            <strong>Mesaj:</strong> {{ msg.message }} - 
            <strong>Durum:</strong> {{ msg.status }} - 
            <strong>Oylama:</strong> {{ msg.vote }}
          </span>
          <button @click="editMessage(msg)">Düzenle</button>
          <button @click="confirmDelete(msg.message_id)">Sil</button>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'MessageManager',
    data() {
      return {
        messages: [],
        selectedMessage: {
          message_id: null,
          web_id: null,
          message_type_id: null,
          from_user_id: null,
          responder_user_id: null,
          subject: '',
          message: '',
          status: '',
          vote: 0,
        },
        isEdit: false,
      };
    },
    methods: {
      async fetchMessages() {
        try {
          const response = await axios.get('/admin/messages');
          this.messages = response.data;
        } catch (error) {
          console.error('Mesajlar alınırken hata oluştu:', error);
        }
      },
      async createMessage() {
        try {
          await axios.post('/admin/messages', this.selectedMessage);
          this.fetchMessages();
          this.resetForm();
        } catch (error) {
          console.error('Mesaj eklenirken hata oluştu:', error);
        }
      },
      editMessage(msg) {
        this.selectedMessage = { ...msg };
        this.isEdit = true;
      },
      async updateMessage() {
        try {
          await axios.put(`/admin/messages/${this.selectedMessage.message_id}`, this.selectedMessage);
          this.fetchMessages();
          this.resetForm();
        } catch (error) {
          console.error('Mesaj güncellenirken hata oluştu:', error);
        }
      },
      async deleteMessage(message_id) {
        try {
          await axios.delete(`/admin/messages/${message_id}`);
          this.fetchMessages();
        } catch (error) {
          console.error('Mesaj silinirken hata oluştu:', error);
        }
      },
      confirmDelete(message_id) {
        if (confirm('Bu mesajı silmek istediğinize emin misiniz?')) {
          this.deleteMessage(message_id);
        }
      },
      resetForm() {
        this.selectedMessage = {
          message_id: null,
          web_id: null,
          message_type_id: null,
          from_user_id: null,
          responder_user_id: null,
          subject: '',
          message: '',
          status: '',
          vote: 0,
        };
        this.isEdit = false;
      },
      cancelEdit() {
        this.resetForm();
      },
    },
    mounted() {
      this.fetchMessages();
    },
  };
  </script>
  